import { BN, IdlTypes, BorshCoder, web3 } from "@coral-xyz/anchor";
import { ecdh, publicKeyVerify, publicKeyCreate, publicKeyConvert, privateKeyVerify } from "secp256k1";
import { Dax, IDL } from "./dax";
import keccak256 from 'keccak256';
import { Buffer } from 'buffer';
import { Keypair, PublicKey } from "@solana/web3.js";

interface ScriptParams {
    propertyId: string;
    publisherPubkey: string;
}

interface PostObject {
  requestorPubkey: string | null;
  signature: string | null;
  data: any;
}

declare const nacl: any;
const coder: BorshCoder =new BorshCoder(IDL)
// const SERVER_URL: string = "http://127.0.0.1:8080";
// const WS_URL: string = "ws://127.0.0.1:8080/ws";
// const SERVER_URL: string = "http://192.168.1.6:8080";
// const WS_URL: string = "ws://192.168.1.6:8080/ws";
// const SERVER_URL: string = "https://faithful-sashenka-dax-protocol-37ee3c00.koyeb.app";
// const WS_URL: string = "wss://faithful-sashenka-dax-protocol-37ee3c00.koyeb.app/ws";
const SERVER_URL: string = "https://rollup.adx.so";
const WS_URL: string = "wss://rollup.adx.so/ws";

const PROGRAM_ID = new PublicKey("Dm1y7dP2V6ffJ67T65A8zha5vqNXdBCUUq7KkQLS4VVc");
  
class DaxCommunicator {
    private propertyId: string;
    private publisherPubkey: web3.PublicKey;
    private endpoint: string = SERVER_URL;
    private pingEndpoint: string = SERVER_URL; 
    private websocketEndpoint: string = WS_URL;
    private requestorKeypair: web3.Keypair | null = null;
    private requestorElGamalPrivateKey: Buffer | null = null;
    private requestorSignature: Array<number> | null = null;
    private auctionPubkey: PublicKey | null = null;

    // New properties to store fetched data
    private browserInfo: Record<string, any> = {};
    private ipInfo: Record<string, any> = {};
    private countryCode: string | undefined;
    private keywords: string[] = [];
    private deviceType: DeviceType = "desktop";
    private detectedWallets: Record<string, boolean> = {};

    constructor(params: ScriptParams) {
        this.propertyId = params.propertyId;
        this.publisherPubkey = new web3.PublicKey(params.publisherPubkey);
        this.initializeData();
    }

    private async initializeData(): Promise<void> {
        await Promise.all([
            this.initializeRequestorKeypair(),
            this.fetchBrowserInfo(),
            this.fetchIPInfo(),
            this.fetchCountryCode(),
            this.analyzePageContent(),
            this.detectWallets()
        ]);
    }

    private async fetchBrowserInfo(): Promise<void> {
        this.browserInfo = this.getBrowserInfo();
        this.deviceType = this.getDeviceType();
    }

    private async fetchIPInfo(): Promise<void> {
        this.ipInfo = await this.getIPInfo();
    }

    private async fetchCountryCode(): Promise<void> {
        this.countryCode = await this.getCountryCode();
    }

    private async analyzePageContent(): Promise<void> {
        this.keywords = this.getKeywords();
    }


    private async initializeRequestorKeypair(): Promise<void> {
      // const storedKeypair = localStorage.getItem('requestorKeypair');
      // if (storedKeypair) {
      //   this.loadKeypair(storedKeypair);
      // } else {
        this.generateAndStoreKeypair();
      // }
    }

    private generateAndStoreKeypair(): void {
      try {
        const keypair = new web3.Keypair();
        const keypairString = JSON.stringify({
          publicKey: base58Encode(keypair.publicKey.toBuffer()),
          secretKey: base58Encode(keypair.secretKey)
        });

        localStorage.setItem('requestorKeypair', keypairString);
        this.requestorKeypair = keypair;
        console.log('New Ed25519 keypair generated and stored', this.requestorKeypair);
      } catch (error) {
        console.error('Error generating or storing Ed25519 keypair:', error);
      }
    }

    private loadKeypair(storedKeypair: string): void {
      try {
        const keypairData = JSON.parse(storedKeypair);
        const publicKey = base58Decode(keypairData.publicKey);
        const secretKey = base58Decode(keypairData.secretKey);
        this.requestorKeypair = Keypair.fromSecretKey(secretKey);
        console.log('Ed25519 keypair loaded from storage', this.requestorKeypair.publicKey.toBase58());
      } catch (error) {
        console.error('Error loading Ed25519 keypair:', error);
        this.generateAndStoreKeypair();
      }
    }

    private getRequestorPublicKey(): string | null {
      if (!this.requestorKeypair) {
        this.initializeRequestorKeypair();
      }
      return this.requestorKeypair ? base58Encode(this.requestorKeypair.publicKey.toBuffer()) : null;
    }

    private signData(data: Uint8Array): string | null {
      if (!this.requestorKeypair) {
        this.initializeRequestorKeypair();
      }
      if (this.requestorKeypair) {
        const signature = nacl.sign.detached(data, this.requestorKeypair.secretKey);
        return base58Encode(signature);
      }
      return null;
    }

    private async sendData(
      route: string,
      data: any
    ): Promise<void> {
      try {
        const innerObject = {
          propertyId: this.propertyId,
          publisherPubkey: this.publisherPubkey,
          ...data
        };

        const serializedInnerObject = JSON.stringify(innerObject);
        const signature = this.signData(encodeUTF8(serializedInnerObject));
  
        const url = this.endpoint + "/" + route;

        console.log('Sending...')
        console.log(url)
        console.log(JSON.stringify(data));

        const requestData: RequestInit = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        };
       
        console.log(requestData)

        const response = await fetch(url, requestData);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        console.log(`Data sent successfully to ${this.endpoint}`);
      } catch (error) {
        console.error(`Error sending data to ${this.endpoint}:`, error);
      }
    }


  
    private async getIPInfo(): Promise<Record<string, any>> {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return { ip: data.ip };
      } catch (error) {
        console.error('Error fetching IP:', error);
        return { ip: 'unknown' };
      }
    }
  
    
    private checkUrlParams(): string[] {
      if (typeof window === 'undefined') {
        return [];
      }
      const urlParams = new URLSearchParams(window.location.search);
      return Array.from(urlParams.keys());
    }
  
    private async handleUrlParams(): Promise<void> {
      const params = this.checkUrlParams();
      for (const param of params) {
        switch (param) {
          case 'daxPing':
            await this.sendPingMessage();
            break;
        }
      }
    }
  
    private async sendPingMessage(): Promise<void> {
      const pingData = {
        propertyId: this.propertyId,
        publisherPubkey: this.publisherPubkey,
        message: 'Ping',
        timestamp: new Date().toISOString(),
      };
      await this.sendData('ping', pingData);
    }
  
    private getBrowserInfo(): Record<string, any> {
      if (typeof window === 'undefined' || typeof navigator === 'undefined') {
        return {};
      }
  
      const screen = window.screen;
      const performance = window.performance;
  
      return {
        // Basic browser information
        userAgent: navigator.userAgent,
        language: navigator.language,
        languages: navigator.languages,
        platform: navigator.platform,
        vendor: navigator.vendor,
        
        // Screen and window information
        screenWidth: screen.width,
        screenHeight: screen.height,
        screenColorDepth: screen.colorDepth,
        screenPixelDepth: screen.pixelDepth,
        windowInnerWidth: window.innerWidth,
        windowInnerHeight: window.innerHeight,
        devicePixelRatio: window.devicePixelRatio,
        // Time and timezone information
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timezoneOffset: new Date().getTimezoneOffset(),
        dateTime: new Date().toISOString(),
        // Connection information
        onLine: navigator.onLine,
        connectionType: (navigator as any).connection ? (navigator as any).connection.effectiveType : 'unknown',
        // Browser capabilities
        cookiesEnabled: navigator.cookieEnabled,
        doNotTrack: navigator.doNotTrack,
        // Performance information
        performanceTiming: performance ? performance.timing.toJSON() : null,
        // Browser storage information
        localStorageAvailable: this.isLocalStorageAvailable(),
        sessionStorageAvailable: this.isSessionStorageAvailable(),
  
      };
    }
  
    private isLocalStorageAvailable(): boolean {
      try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
        return true;
      } catch (e) {
        return false;
      }
    }
  
    private isSessionStorageAvailable(): boolean {
      try {
        sessionStorage.setItem('test', 'test');
        sessionStorage.removeItem('test');
        return true;
      } catch (e) {
        return false;
      }
    }
  
    private getCreativeDimensions(): { width: number, height: number } {
        return { 
            width: this.browserInfo.windowInnerWidth || window.innerWidth, 
            height: Math.floor((this.browserInfo.windowInnerHeight || window.innerHeight) * 0.1) 
        };
    }

    private async getCountryCode(): Promise<string | undefined> {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        return data.country_code;
      } catch (error) {
        console.error('Error fetching country code:', error);
        return undefined;
      }
    }

    private getKeywords(): string[] {
      const text = document.body.innerText;
      const words = text.toLowerCase().match(/\b\w+\b/g) || [];
      const wordCounts: {[key: string]: number} = {};
      
      // Count word occurrences
      words.forEach(word => {
        if (word.length > 3) { // Ignore short words
          wordCounts[word] = (wordCounts[word] || 0) + 1;
        }
      });

      // Find common two-word phrases
      const phraseCounts: {[key: string]: number} = {};
      for (let i = 0; i < words.length - 1; i++) {
        if (words[i].length > 3 && words[i+1].length > 3) {
          const phrase = `${words[i]} ${words[i+1]}`;
          phraseCounts[phrase] = (phraseCounts[phrase] || 0) + 1;
        }
      }

      // Combine single words and phrases, sort by frequency
      const combinedCounts = {...wordCounts, ...phraseCounts};
      const sortedWords = Object.entries(combinedCounts)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 3)
        .map(([word]) => word);

      return sortedWords;
    }

    private getDeviceType(): DeviceType {
      const ua = navigator.userAgent;
      if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobileWeb";
      }
      return "desktop";
    }

    private getPropertyCategory(): PropertyCategory {
      // This should be set based on your website's content
      // For now, we'll return a default value
      return "other";
    }

 
   
    private async detectWallets(): Promise<Record<string, boolean>> {
      const wallets: Record<string, boolean> = {
        // (wallet list remains the same)
      };
  
      if (typeof window === 'undefined') {
        return wallets;
      }
  
      // Use type assertion to avoid TypeScript errors
      const win = window as any;

      // EVM wallets
      wallets.metamask = await detectMetaMask();
      wallets.braveEthereum = win.braveEthereum?.isBraveWallet || false;

      // Solana wallet detection
      wallets.phantom = win.phantom?.solana?.isPhantom || false;
      wallets.solflare = win.solflare?.isSolflare || false;
      wallets.backpack = win.backpack || false;
      wallets.braveSolana = win.braveSolana?.isBraveWallet || false;

      return wallets;
    }
  
    async getNonce(): Promise<BN> {
      // TODO: Replace this method with something which fetches it from
      // the rollup
      // return this.getNonceForPubkey(this.publicKey);
        return new BN(0)
    }

    generateElGamalKeyPairFromSignedNonce(
        signedNonce: Buffer,
    ): [Buffer, number[]] { 
        // const elGamalPrivateKey = crypto.randomBytes(32); 
        const elGamalPrivateKey = signedNonce.slice(0, 32);
        const elGamalPublicKey = publicKeyCreate(elGamalPrivateKey, true);
        if (!publicKeyVerify(elGamalPublicKey)) {
            throw new Error("Generated public key is not valid");
        }
        return [elGamalPrivateKey, Array.from(elGamalPublicKey)]
    }   

    generateElGamalKeyPair(
        nonce: BN
    ): [Buffer, number[]] { 
        if (!this.requestorKeypair) {
          this.initializeRequestorKeypair();
        }
        const signedNonce = Buffer.from(
            nacl.sign.detached(
                nonce.toArrayLike(Buffer, 'le', 8), 
                this.requestorKeypair!.secretKey
            )
        );
        return this.generateElGamalKeyPairFromSignedNonce(signedNonce);
    }   



    async generatePlacement(): Promise<[IdlTypes<Dax>["Placement"], IdlTypes<Dax>["PlacementContext"], any, Uint8Array, Uint8Array]> {
      if (!this.requestorKeypair) {
          await this.initializeRequestorKeypair();
      }
      const nonce = await this.getNonce()
      const creativeDimensions = this.getCreativeDimensions();
      const [anchorContext, jsonContext] = generateContext(
          creativeDimensions.width,
          creativeDimensions.height,
          this.countryCode,
          undefined, // age (can't be reliably determined)
          undefined, // gender (can't be reliably determined)
          undefined, // netWorth (can't be reliably determined)
          undefined, // income (can't be reliably determined)
          undefined, // education (can't be reliably determined)
          this.deviceType,
          this.getPropertyCategory(),
          this.keywords
      );

      const [elGamalPrivateKey, elGamalPublicKey] = this.generateElGamalKeyPair(nonce);
      this.requestorElGamalPrivateKey = elGamalPrivateKey;
      const contextBytes = coder.types.encode("PlacementContext", anchorContext);
      const contextHash = Array.from(keccak256(Buffer.from(contextBytes)));

      const placement = {
          requestor: this.requestorKeypair?.publicKey,
          nonce: nonce,
          publisher: this.publisherPubkey,
          propertyId: Number(this.propertyId),
          elGamalPublicKey: elGamalPublicKey,
          contextHash: contextHash
      } as IdlTypes<Dax>["Placement"];      

      const placementBytes = coder.types.encode("Placement", placement);
      const requestorSignature = Uint8Array.from(
          nacl.sign.detached(
              placementBytes, 
              this.requestorKeypair?.secretKey
          )
      );

      return [placement, anchorContext, jsonContext, requestorSignature, elGamalPrivateKey];
  }

  
    async requestPlacementAuction() {
      const [placement, anchorContext, jsonContext, requestorSignature, elGamalPrivateKey] = await this.generatePlacement();
      this.requestorSignature = Array.from(requestorSignature);
      // Store the elGamalPrivateKey
      // Send the placement and context to the node
      // Open up a websocket connection
      console.log(placement);
      console.log(jsonContext);

      this.sendData(
        "submit",
        {
          SubmitPlacement: {
            placement: {
              requestor: Array.from(placement?.requestor.toBuffer()),
              nonce: Number(placement?.nonce),
              publisher: Array.from(placement?.publisher.toBuffer()),
              property_id: placement?.propertyId,
              el_gamal_public_key: placement?.elGamalPublicKey,
              context_hash: placement.contextHash
            },
            context: jsonContext,
            requestor_signature: Array.from(requestorSignature)
          }
        }
      )

      const auctionPubkey = deriveAuctionPubkey(Buffer.from(requestorSignature));
      console.log("Auction pubkey:", auctionPubkey.toBase58());
      this.auctionPubkey = auctionPubkey;
    }

    private renderCreative(creativeData: any): void {
        const daxContainer = document.getElementById('dax-ad-container');
        if (!daxContainer) {
            console.error('Dax container not found. Please add a div with id "dax-ad-container" to your HTML.');
            return;
        }
        // Clear previous content
        daxContainer.innerHTML = '';

        // Create and append the creative element based on the type
        if ("Image" in creativeData.asset) {
            const img = document.createElement('img');
            img.src = creativeData.asset.Image.uri;
            img.alt = creativeData.asset.Image.alt || 'Advertisement';
            img.style.width = '100%';
            img.style.height = 'auto';
            daxContainer.appendChild(img);
        } 
        // else if (creativeData.type === 'html') {
        //     const iframe = document.createElement('iframe');
        //     iframe.srcdoc = creativeData.content;
        //     iframe.style.width = '100%';
        //     iframe.style.height = '100%';
        //     iframe.style.border = 'none';
        //     this.daxContainer.appendChild(iframe);
        // }
        else {
            console.error('Unsupported creative type:', creativeData.type);
        }
    }

    async subscribeToBids(): Promise<void> {
      const ws = new WebSocket(WS_URL);

      ws.onopen = () => {
          ws.send('SUBSCRIBE BIDS');
          console.log('Subscribed to BIDS');
      };

      ws.onmessage = async (event: MessageEvent) => {
          try {
            
              console.log(event.data);
              const bid = JSON.parse(event.data as string);
              console.log('Bid:', bid);
              console.log('Auction pubkey:', new PublicKey(bid.auction).toBase58());
              console.log('Bid auction pubkey:', this.auctionPubkey?.toBase58());

              if (new PublicKey(bid.auction).toBase58() !== this.auctionPubkey?.toBase58()) {
                console.log('Skipping bid because auction pubkey does not match');
                return;
              }

              // Close the websocket for now if a bid is received
              ws.close();

              const [decryptedBid, decryptedCreativeId, sharedSecret] = await decryptBid(this.requestorElGamalPrivateKey!, bid.bid.encrypted_value);
              console.log('Decrypted bid:', decryptedBid);
              console.log('Decrypted creative id:', decryptedCreativeId.toBase58());

              // const requesterSignature = requestor.auctionToSignature.get(bid.auction);
              // if (!requesterSignature) {
              //     console.error('Requester signature not found for auction:', bid.auction);
              //     return;
              // }

              // const requestorElGamalPrivateKey = requestor.signatureToElGamalPrivateKey.get(Buffer.from(requesterSignature).toString('hex'));
              // if (!requestorElGamalPrivateKey) {
              //     console.error('El Gamal private key not found for signature:', Buffer.from(requesterSignature).toString('hex'));
              //     return;
              // }

              const result = await generateBidAcceptance(this.requestorKeypair, this.requestorElGamalPrivateKey, bid.bid);
              if (result) {
                  const [sharedSecret, requestorAcceptanceSignature] = result;
                  console.log('Shared secret:', sharedSecret);
                  console.log('Requestor acceptance signature:', requestorAcceptanceSignature);

                  this.sendData(
                    "submit",
                    {
                      AcceptBid: {
                        bid: bid.bid,
                        bidder_signature: bid.bidder_signature,
                        requestor_acceptance_signature: Array.from(requestorAcceptanceSignature),
                        requestor: Array.from(this.requestorKeypair!.publicKey.toBuffer()),
                        requestor_signature: Array.from(this.requestorSignature!),
                        shared_secret: Array.from(sharedSecret),
                        content_id:  Array.from(decryptedCreativeId.toBuffer()),
                        bid_value: decryptedBid
                      }
                    }
                  )
                  
                  console.log('Bid acceptance submitted');

                  const creative = await fetch(SERVER_URL + "/creative/" + decryptedCreativeId.toBase58());
                  console.log('Creative:', creative);

                  const creativeData = await creative.json();
                  console.log('Creative data:', creativeData);

                  // Render the creative
                  this.renderCreative(creativeData);
              }
          } catch (e) {
              console.error('Error processing message:', e);
          }
      };

      ws.onerror = (error: Event) => {
          console.error('WebSocket error:', error);
      };

      ws.onclose = () => {
          console.log('WebSocket connection closed');
      };
    }

    public async run(): Promise<void> {
      await this.initializeData();
      await this.handleUrlParams();
      await this.subscribeToBids();
      await this.requestPlacementAuction();
  }
}
  
// This part will be executed when the script is loaded
// (function() {
//     const script = document.currentScript as HTMLScriptElement;
//     const propertyId = script.getAttribute('data-property-id');
//     const publisherPubkey = script.getAttribute('data-publisher-pubkey');
  
//     if (!propertyId || !publisherPubkey) {
//       console.error('Missing required attributes: data-property-id or data-publisher-pubkey');
//       return;
//     }
  
//     const communicator = new DaxCommunicator({
//       propertyId,
//       publisherPubkey,
//     });
  
//     communicator.run().catch(error => {
//       console.error('Error running DaxCommunicator:', error);
//     });
// })();




async function detectMetaMask(): Promise<boolean> {
  const win = window as any;

  if (typeof window === 'undefined' || typeof win.ethereum === 'undefined') {
    return false;
  }

  let isMetaMask = false;

  // Check if MetaMask is installed
  if (win.ethereum.isMetaMask) {
    isMetaMask = true;
  } else if (win.ethereum.providers) {
    // Check in case of multiple wallets
    isMetaMask = win.ethereum.providers.some((provider: any) => provider.isMetaMask);
  }

  if (!isMetaMask) {
    return false;
  }

  // Optionally, you can check if MetaMask is connected
  try {
    const accounts = await win.ethereum.request({ method: 'eth_accounts' });
    return accounts.length > 0;
  } catch (error) {
    console.error('Error checking MetaMask connection:', error);
    return false;
  }
}

// Usage
detectMetaMask().then((isMetaMaskConnected) => {
  if (isMetaMaskConnected) {
    console.log('MetaMask is installed and connected');
  } else {
    console.log('MetaMask is not installed or not connected');
  }
});


const BASE58_ALPHABET = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
  
  function base58Encode(buffer: Uint8Array): string {
    const digits: number[] = [0];
    for (let i = 0; i < buffer.length; i++) {
      let carry = buffer[i];
      for (let j = 0; j < digits.length; j++) {
        carry += digits[j] << 8;
        digits[j] = carry % 58;
        carry = (carry / 58) | 0;
      }
      while (carry > 0) {
        digits.push(carry % 58);
        carry = (carry / 58) | 0;
      }
    }

    let result = '';
    for (let i = 0; i < buffer.length && buffer[i] === 0; i++) {
      result += '1';
    }
    for (let i = digits.length - 1; i >= 0; i--) {
      result += BASE58_ALPHABET[digits[i]];
    }
    return result;
  }

  function base58Decode(str: string): Uint8Array {
    const bytes: number[] = [0];
    for (let i = 0; i < str.length; i++) {
      const char = str[i];
      const value = BASE58_ALPHABET.indexOf(char);
      if (value === -1) {
        throw new Error('Invalid base58 character');
      }
      for (let j = 0; j < bytes.length; j++) {
        bytes[j] *= 58;
      }
      bytes[0] += value;
      let carry = 0;
      for (let j = 0; j < bytes.length; j++) {
        bytes[j] += carry;
        carry = bytes[j] >> 8;
        bytes[j] &= 0xff;
      }
      while (carry > 0) {
        bytes.push(carry & 0xff);
        carry >>= 8;
      }
    }

    for (let i = 0; i < str.length && str[i] === '1'; i++) {
      bytes.push(0);
    }

    return new Uint8Array(bytes.reverse());
  }

  // UTF-8 encoding and decoding functions
  function encodeUTF8(str: string): Uint8Array {
    return new TextEncoder().encode(str);
  }

  function decodeUTF8(uint8Array: Uint8Array): string {
    return new TextDecoder().decode(uint8Array);
  }


/////// CONTEXT ////////

type DeviceType = "desktop" | "mobileWeb" | "nativeApp";
type PropertyCategory = "news" | "sports" | "entertainment" | "finance" | "lifestyle" | "other";
type Gender = "male" | "female" | "other";
type Education = "highSchool" | "bachelors" | "masters" | "phd" | "other";


function generateContext(
  creativeWidth: number,
  creativeHeight: number,
  countryCode?: string,
  age?: number,
  gender?: Gender,
  netWorth?: number,
  income?: number,
  education?: Education,
  deviceType?: DeviceType,
  propertyCategory?: PropertyCategory,
  keywords: string[] = []
): [any, any] {
  return [
    generateAnchorContext(
      creativeWidth,
      creativeHeight,
      countryCode,
      age,
      gender,
      netWorth,
      income,
      education,
      deviceType,
      propertyCategory,
      keywords
    ),
    generateJsonContext(
      creativeWidth,
      creativeHeight,
      countryCode,
      age,
      gender,
      netWorth,
      income,
      education,
      deviceType,
      propertyCategory,
      keywords
    )
  ]
}

function generateAnchorContext(
  creativeWidth: number,
  creativeHeight: number,
  countryCode?: string,
  age?: number,
  gender?: Gender,
  netWorth?: number,
  income?: number,
  education?: Education,
  deviceType?: DeviceType,
  propertyCategory?: PropertyCategory,
  keywords: string[] = []
): IdlTypes<Dax>["PlacementContext"] {
  const propertVec: any[] = [];
  const userVec: any[] = [];

  if (deviceType) {
      propertVec.push({ deviceType: { value: { [deviceType]: {} }} });
  }

  if (propertyCategory) {
      propertVec.push({ category: { value: { [propertyCategory]: {} } } });
  }

  if (keywords.length > 0) {
      keywords.forEach((keyword) => {
          propertVec.push({ keyword: { value: keyword } });
      });
  }

  if (countryCode) {
      userVec.push({ countryCode: { value: new TextEncoder().encode(countryCode).slice(0, 2) } });
  }

  if (age !== undefined) {
      userVec.push({ age: { value: age } });
  }

  if (netWorth !== undefined) {
      userVec.push({ netWorth: { value: netWorth } });
  }

  if (income !== undefined) {
      userVec.push({ income: { value: income } });
  }

  if (gender) {
      userVec.push({ gender: { value: { [gender]: {} } } });
  }

  if (education) {
      userVec.push({ education: { value: { [education]: {} } } });
  }

  console.log(propertVec);
  return {
      creative: { image: { width: creativeWidth, height: creativeHeight } },
      property: propertVec,
      user: userVec,
  };

}

function uppercaseFirstLetter(
    str: string,
): string {
  return str.slice(0, 1).toUpperCase() + str.slice(1)
}

function generateJsonContext(
    creativeWidth: number,
    creativeHeight: number,
    countryCode?: string,
    age?: number,
    gender?: Gender,
    netWorth?: number,
    income?: number,
    education?: Education,
    deviceType?: DeviceType,
    propertyCategory?: PropertyCategory,
    keywords: string[] = []
): any {

    const propertyVec: any[] = [];
    const userVec: any[] = [];

    if (deviceType) {
        propertyVec.push({ "DeviceType": { value:  uppercaseFirstLetter(deviceType) } });
    }

    if (propertyCategory) {
        propertyVec.push({ "Category": { value: uppercaseFirstLetter(propertyCategory) } });
    }

    if (keywords.length > 0) {
        keywords.forEach((keyword) => {
          propertyVec.push({ "Keyword": { value: keyword } });
        });
    }

    if (countryCode) {
        userVec.push({ "CountryCode": { value: Array.from(new TextEncoder().encode(countryCode).slice(0, 2)) }});
    }

    if (age !== undefined) {
        userVec.push({ "Age": { value: age } });
    }

    if (netWorth !== undefined) {
        userVec.push({ "NetWorth": { value: netWorth } });
    }

    if (income !== undefined) {
        userVec.push({ "Income": { value: income } });
    }

    if (gender) {
        userVec.push({ "Gender": { value:  uppercaseFirstLetter(gender) }});
    }

    if (education) {
        userVec.push({ "Education": { value: uppercaseFirstLetter(education) } });
    }

    console.log(propertyVec);
    
    return {
        creative: { "Image": { width: creativeWidth, height: creativeHeight } },
        property: propertyVec,
        user: userVec,
    };

}

function deriveAuctionPubkey(
  placementIdBuffer: Buffer
): PublicKey {
  const [pk, _] = PublicKey.findProgramAddressSync(
      [
        encodeUTF8("auction"), 
        placementIdBuffer.slice(0, 32),
        placementIdBuffer.slice(33),
      ],
      PROGRAM_ID
  );
  return pk
}


function deriveSharedSecret(
  privateKey: Buffer, 
  publicKey: Buffer
): Buffer {
  if (!privateKeyVerify(privateKey)) {
      throw new Error('Invalid private key');
  }
  const sharedSecret = Buffer.from(ecdh(publicKey, privateKey));
  return sharedSecret
}

function generateBidAcceptance(
  keypair: Keypair | null,
  elGamalPrivateKey: Buffer | null,
  bid: any,
): [Buffer, Buffer] {
  console.log("Keypair", keypair);
  console.log("ElGamal private key", elGamalPrivateKey);
  if (!keypair || !elGamalPrivateKey) {
    throw new Error("Keypair or elGamal private key not provided");
  }
  const bidElGamalPublicKey = Buffer.from(publicKeyConvert(
    Buffer.from(bid.encrypted_value.ephemeral_public_key),
    true
  ));
  const sharedSecret = deriveSharedSecret(
      elGamalPrivateKey, 
      bidElGamalPublicKey
  );
  const requestorSignature = Buffer.from(nacl.sign.detached(
    sharedSecret, 
    keypair.secretKey
  ));
  return [sharedSecret, requestorSignature];
}

async function decryptBid(
  elGamalPrivateKey: Buffer,
  encryptedValue: any,
): Promise<[number, PublicKey, Buffer]>{
  const bidElGamalPublicKey = Buffer.from(publicKeyConvert(
      Buffer.from(encryptedValue.ephemeral_public_key),
      true
  ));
  const sharedSecret = deriveSharedSecret(
      elGamalPrivateKey, 
      bidElGamalPublicKey
  );
  const aesKey = keccak256(sharedSecret);
  const iv = Buffer.from(encryptedValue.encrypted_data.slice(0, 12));
  const ciphertext = Buffer.from(encryptedValue.encrypted_data.slice(12));
  const authTag = Buffer.from(encryptedValue.auth_tag);
  try {
      const decrypted = await decryptFunction(ciphertext, aesKey, iv, authTag);
      const decryptedBuffer = Buffer.from(decrypted);
      console.log("Decrypted buffer", decryptedBuffer);
      const decryptedBidBn = new BN(decryptedBuffer.slice(0, 8), 'le');
      const decryptedBid = decryptedBidBn.toNumber();
      const decryptedCreativeId = new PublicKey(decryptedBuffer.slice(8));
      return [decryptedBid, decryptedCreativeId, sharedSecret];
  } catch (error) {
      console.log(error);
      throw new Error(`Decryption failed`);
  }
}

// This function will detect the environment and return the appropriate decryption function
function getDecryptionFunction() {
  if (typeof window !== 'undefined' && window.crypto && window.crypto.subtle) {
    // Browser environment
    return async (
      encryptedData: Uint8Array,
      aesKey: Uint8Array,
      iv: Uint8Array,
      authTag: Uint8Array
    ): Promise<ArrayBuffer> => {
      const algorithm = { name: 'AES-GCM', iv: iv };
      const key = await window.crypto.subtle.importKey(
        'raw',
        aesKey,
        algorithm,
        false,
        ['decrypt']
      );
      
      const encryptedDataWithAuthTag = new Uint8Array(encryptedData.length + authTag.length);
      encryptedDataWithAuthTag.set(encryptedData);
      encryptedDataWithAuthTag.set(authTag, encryptedData.length);

      return window.crypto.subtle.decrypt(
        algorithm,
        key,
        encryptedDataWithAuthTag
      );
    };
  } else {
    // Node.js environment
    return (
      encryptedData: Buffer,
      aesKey: Buffer,
      iv: Buffer,
      authTag: Buffer
    ): Buffer => {
      // We'll implement this later for Node.js environment
      throw new Error("Node.js decryption not implemented");
    };
  }
}

// The decryption function to be used
const decryptFunction = getDecryptionFunction();


export { DaxCommunicator };