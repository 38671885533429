import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DaxCommunicator } from "./dax-embed";

const communicator = new DaxCommunicator({
  propertyId: '1',
  publisherPubkey: '2tyyPm5yV35cWQxGZRaMzx7pU6CsuLHuFdpUnv7hpXz9'
});
communicator.run().catch(console.error);

// Updated mock blog data with real image URLs
const mockBlogs = [
  {
    id: 1,
    title: 'The Future of AI',
    excerpt: 'Exploring the latest advancements in artificial intelligence and their potential impact on society...',
    image: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80'
  },
  {
    id: 2,
    title: 'Sustainable Living',
    excerpt: 'Tips for reducing your carbon footprint and adopting eco-friendly practices in your daily life...',
    image: 'https://images.unsplash.com/photo-1542601906990-b4d3fb778b09?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80'
  },
  {
    id: 3,
    title: 'Healthy Eating Habits',
    excerpt: 'Discover the best foods for maintaining a balanced diet and improving your overall well-being...',
    image: 'https://images.unsplash.com/photo-1490818387583-1baba5e638af?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80'
  },
  {
    id: 4,
    title: 'Travel Adventures',
    excerpt: 'Exploring hidden gems and off-the-beaten-path destinations for your next unforgettable journey...',
    image: 'https://images.unsplash.com/photo-1469854523086-cc02fe5d8800?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80'
  },
];

const theme = createTheme({
  palette: {
    primary: {
      main: '#2c3e50',
    },
    secondary: {
      main: '#e74c3c',
    },
    background: {
      default: '#ecf0f1',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 600,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 12px 20px rgba(0, 0, 0, 0.15)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

function App() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(mockBlogs);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 700 }}>
              Modern Blog
            </Typography>
            <Button color="inherit">Home</Button>
            <Button color="inherit">About</Button>
            <Button color="inherit">Contact</Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg" sx={{ mt: 6, mb: 6, flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h4" component="h1" gutterBottom>
                The Best Place to Find the Best Content
              </Typography>
              <Divider sx={{ mb: 4 }} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" component="h2" gutterBottom>
                Featured Posts
              </Typography>
              <Slider {...settings}>
                {blogs.map((blog) => (
                  <div key={blog.id}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', m: 1 }}>
                      <CardMedia
                        component="img"
                        height="200"
                        image={blog.image}
                        alt={blog.title}
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h5" component="div" gutterBottom>
                          {blog.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {blog.excerpt}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small" color="secondary" href={`/blog/${blog.id}`}>
                          Read More
                        </Button>
                      </CardActions>
                    </Card>
                  </div>
                ))}
              </Slider>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" component="h2" gutterBottom>
                Sponsored Content
              </Typography>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <div id="dax-ad-container" className="ad-container" style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    AdX Ad Container
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Box component="footer" sx={{ bgcolor: 'primary.main', color: 'white', py: 3, mt: 'auto' }}>
          <Container maxWidth="lg">
            <Typography variant="body2" align="center">
              © 2024 Modern Blog. All rights reserved.
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;